import {
  apiCheckAuth,
  apiGetChannels,
  apiGetPostsByCid,
  apiGetPost,
  apiGetMe,
  apiGetBasicStats,
  apiGetStats,
} from '@/functions/api'

import i18n from '@/i18n'

export const handleNotOk = (result, method) => {
  if (result.error.code === 401) {
    return {
      ok: false,
      next: 'login',
    }
  }
  if (result.error.code === 503) {
    //todo
    return {
      ok: false,
      next: false,
    }
  }
  return {
    ok: false,
    next: vm => vm.applyError({
      blocking: true,
      title: 'Unknown getTemplates error',
    })
  }
}

export const ensureCheckAuth = async ({store}) => {
  if (store.state.auth.userIdFetched) {
    return {ok: true}
  }

  const result = await apiCheckAuth()
  if (!result.ok) {
    if (result.error.code === 401) {
      return {
        ok: false,
        next: 'login'
      }
    }
    if (result.error.code === 503) {
      //todo если нет интернета/сервер недоступен
    } else {
      //а какие еще могут быть ошибки? (никаких)
    }
    return {
      ok: false,
      next: vm => vm.applyError({
        blocking: true,
        name: 'Auth Error',
      })
    }
    // return this.showErrorAlert('checkAuth', result.error, this.$store.state.auth.user_id)
  }

  const {user_id} = result.result
  await store.commit('auth/setUserId', user_id)
  return {ok: true}
}

export const ensureGetUser = async ({store}) => {
  if (store.state.auth.userFetched) {
    return {ok: true}
  }

  const result = await apiGetMe()
  if (!result.ok) {
    if (result.error.code === 401) {
      return {
        ok: false,
        next: 'login',
      }
    }
    if (result.error.code === 400) { // пользователь не найден
      // чтобы удалились данные о пользователе
      return {
        ok: false,
        next: 'logout',
      }
    }
    return {
      ok: false,
      next: vm => vm.applyError({
        blocking: true,
        name: 'getUser Error',
      })
    }
  }

  store.commit('auth/setUser', result.result)
  i18n.locale = result.result.language || 'en'
  return {ok: true}
}

export const ensureGetChannels = async ({store}) => {
  if (store.state.auth.userChannelsFetched) {
    return {ok: true}
  }

  const result = await apiGetChannels(store.state.auth.userId)
  if (!result.ok) {
    return handleNotOk(result, 'getChannels')
  }
  await store.commit('auth/setUserChannels', result.result.channels)
  return {ok: true}
}

export const getPostsByCid = async ({cid, limit, offset}) => {
  const result = await apiGetPostsByCid({cid, limit, offset})

  if (!result.ok) {
    return handleNotOk(result, 'getPosts')
  }

  return {
    ok: true,
    next: vm => vm.applyPostsResult(result.result)
  }
}

export const getPost = async (id) => {
  const result = await apiGetPost(id)

  if (!result.ok) {
    if (result.error.code === 401) {
      return {
        ok: false,
        next: 'login',
      }
    }
    if (result.error.code === 503) {
      //todo
      return {
        ok: false,
        next: false,
      }
    }
    if (result.error.code === 404) {
      return {
        ok: false,
        next: vm => vm.applyError({
          blocking: true,
          title: 'Post not found',
        })
      }
    }
    return {
      ok: false,
      next: vm => vm.applyError({
        blocking: true,
        title: 'Unknown getPost error',
      })
    }
  }

  return {
    ok: true,
    next: vm => vm.applyPost(result.result.post)
  }
}

export const getBasicStats = async (id) => {
  const result = await apiGetBasicStats(id)

  if (!result.ok) {
    if (result.error.code === 401) {
      return {
        ok: false,
        next: 'login',
      }
    }
    if (result.error.code === 503) {
      //todo
      return {
        ok: false,
        next: false,
      }
    }
    return {
      ok: false,
      next: vm => vm.applyError({
        blocking: true,
        name: 'getBasicStats Error',
      })
    }
  }

  return {
    ok: true,
    next: vm => vm.applyBasicStats(result.result)
  }
}

export const getStats = async (id) => {
  const result = await apiGetStats(id)

  if (!result.ok) {
    if (result.error.code === 401) {
      return {
        ok: false,
        next: 'login',
      }
    }
    if (result.error.code === 503) {
      //todo
      return {
        ok: false,
        next: false,
      }
    }
    return {
      ok: false,
      next: vm => vm.applyError({
        blocking: true,
        name: 'getStats Error',
      })
    }
  }

  return {
    ok: true,
    next: vm => vm.applyStats(result.result.stats)
  }
}
