/*
 * Важное замечание при создании миксинов:
 * Миксины для переиспользования кода должны реализовывать только методы!
 * Использующий их компонент сам решит, когда его запустить
 * Это нужно для того, чтобы можно было соединять проверки в цепочки, когда одна зависит от другой
 * Название метода, которым активируется миксин, должно совпадать с названием миксина
 * Миксины с проверками должны возвращать Boolean (или промис на него)*/

export const showErrorAlert = {
  methods: {
    showErrorAlert(method, error, params) {
      const h = this.$createElement
      this.$msgbox({
        title: this.$i18n.t('error_alert.error_title'),
        message: h('p', null, [
          h('span', null, this.$i18n.t('error_alert.error_description')),
          h('textarea', {class: 'error-textarea'}, JSON.stringify({
            method,
            timestamp: Date.now(),
            error,
            params,
          }, null, 2)),
        ]),
        confirmButtonText: this.$i18n.t('common.close'),
      }).catch(() => {})
    },
  }
}

export const nextTick = {
  methods: {
    nextTick() {
      return new Promise((resolve) => {
        return this.$nextTick(resolve)
      })
    },
  }
}
