<template>
  <message v-if="error">
    <template slot="title">{{error.title || 'Unknown error'}}</template>
    <template slot="content">{{error.message || ''}}</template>
  </message>
  <div class="stats-wrapper" v-else>
    <helmet>
      <title>{{$t('title.stats')}}</title>
    </helmet>
    <div class="stats">
      <span class="title">{{$t('stats.titles.count')}}</span>
      <tchart :data="countData" :colors="colors" class="tchart"/>

      <span class="title">{{$t('stats.titles.growth')}}</span>
      <tchart :data="growthData" :colors="colors" class="tchart"/>
    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'
  import Message from '@/components/Message'

  import Tchart from '@/plugins/tchart/Tchart.vue'

  import {
    showErrorAlert,
  } from '../mixins/utils'

  import store from '@/store'
  import { chain } from '@/functions/utils'

  import {
    ensureCheckAuth,
    ensureGetUser,
    getPost,
    getStats,
  } from '@/functions/data'

  export default {
    name: 'Stats',
    mixins: [
      showErrorAlert,
    ],
    components: {
      Helmet,
      Message,

      Tchart,
    },
    data() {
      return {
        colors: {
          circleFill: '#ffffff',
          line: '#f2f4f5',
          zeroLine: '#ecf0f3',
          selectLine: '#dfe6eb',
          text: '#96a2aa',
          preview: '#eef2f5',
          previewAlpha: 0.8,
          previewBorder: '#b6cfe1',
        },

        lineColors: [
          '#c0513a',
          '#62c03a',
          '#3ac0a0',
          '#3aa2c0',
          '#3a5ec0',
          '#793ac0',
          '#c03aab',
        ],

        post: {},
        stats: [],

        error: false,
      }
    },
    methods: {
      applyPost(post) {
        this.post = post
      },
      applyStats(stats) {
        this.stats = stats
      },
      applyError(error) {
        this.error = error
      },
    },
    computed: {
      buttonTexts() {
        if (!this.post || !this.post.buttons_flatten) {
          return []
        }
        return this.post.buttons_flatten
          .filter((button) => button.type === 'answer')
          .map((button) => button.text)
      },

      commonData() {
        if (this.error) {
          return
        }
        if (!this.post) {
          return
        }

        let types = {
          'x': 'x',
        }
        let names = {}
        let colors = {}

        if (this.buttonTexts.length > 0) {
          this.buttonTexts.forEach((button, i) => {
            types['y' + i] = 'line'
            names['y' + i] = button
            colors['y' + i] = this.lineColors[i % this.lineColors.length]
          })
        }

        return {
          types,
          names,
          colors,
        }
      },
      countData() {
        if (this.error) {
          return
        }
        if (!this.post) {
          return
        }

        let columns = [
          ['x'],
        ]

        if (this.buttonTexts.length > 0) {
          this.buttonTexts.forEach((button, i) => {
            columns.push(['y' + i])
          })
        }

        this.stats.forEach((stat) => {
          columns[0].push(stat.ts)
          stat.answers.forEach((answerCount, i) => {
            const column = columns[i + 1]
            if (column) {
              column.push(answerCount)
            }
          })
        })
        return {
          columns,
          ...this.commonData
        }
      },
      growthData() {
        if (this.error) {
          return
        }
        if (!this.post) {
          return
        }

        let columns = [
          ['x'],
        ]
        let prevAnswersCount = []

        if (this.buttonTexts.length > 0) {
          this.buttonTexts.forEach((button, i) => {
            columns.push(['y' + i])
            prevAnswersCount.push(0)
          })
        }

        this.stats.forEach((stat) => {
          columns[0].push(stat.ts)

          stat.answers.forEach((answerCount, i) => {
            const column = columns[i + 1]
            if (column) {
              column.push(answerCount - prevAnswersCount[i])
            }
            prevAnswersCount[i] = answerCount
          })
        })
        return {
          columns,
          ...this.commonData
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ({to}) => getPost(to.params.id),
          ({to}) => getStats(to.params.id),
        ]
      )
    },
  }
</script>

<style scoped lang="stylus">
  .stats-wrapper
    flex-grow 1
    display flex
    flex-direction column
    justify-content space-between
    align-items center

    .stats
      flex-grow 1
      display flex
      flex-direction column

      width: 100%
      padding 30px 20px 30px

      .title:not(:firstChild)
        padding-top 20px

  .tchart
    width 100%
</style>