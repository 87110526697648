<template>
  <div class="tchart" ref="tchart"/>
</template>

<script>
  import {TChart} from './tchart'

  export default {
    name: 'Tchart',
    props: {
      data: {
        type: Object,
        default: () => ({
          columns: [],
          types: [],
          names: [],
          colors: []
        }),
      },
      colors: {
        type: Object,
        default: () => ({
          circleFill: '#ffffff',
          line: '#f2f4f5',
          zeroLine: '#ecf0f3',
          selectLine: '#dfe6eb',
          text: '#96a2aa',
          preview: '#eef2f5',
          previewAlpha: 0.8,
          previewBorder: '#b6cfe1',
        }),
      },
    },
    data() {
      return {
        tchart: undefined,
      }
    },
    mounted() {
      this.tchart = new TChart(this.$refs.tchart)
      this.tchart.setData(this.data)
      this.tchart.setColors(this.colors)
    },
    beforeDestroy() {
      if (this.tchart && this.tchart.destroy && typeof this.tchart.destroy === 'function') {
        this.tchart.destroy()
      }
    },
    watch: {
      data(newData) {
        console.log(newData)
        this.tchart.setData(newData)
      },
      colors(newColors) {
        this.tchart.setColors(newColors)
      }
    }
  }
</script>

<style lang="stylus">
  .tchart {
    position relative
  }
  /* popup */

  .tchart .popup {
    pointer-events: none;

    background: white;
    border-radius: 4px;
    box-shadow: 0 1px 1px 1px #d8d8d8;

    position: absolute;
    padding: 6px 2px 4px;

    max-width: 166px;
  }

  .dark-theme .tchart .popup {
    background: #253241;
    box-shadow: 0 1px 1px 1px #1e2834;
  }

  .tchart .popup .title {
    font-size: 12px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .tchart .popup .column {
    float: left;
    margin-top: 8px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .tchart .popup .value {
    font-weight: bold;
    font-size: 14px;
  }

  .tchart .popup .label {
    font-size: 10px;
    margin-bottom: 1px;
  }

  /* CHECK */

  .tchart .checks {
    padding-left: 11px;
    padding-right: 11px;
  }

  .tchart .checkbox {
    box-sizing: border-box;
    display: block;
    position: relative;
    float: left;
    border: 1px solid #e6ecf0;
    border-radius: 16px;

    padding: 9px 14px 8px 37px;

    margin-right: 14px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 13px;

    color: #43484b;
  }

  .dark-theme .tchart .checkbox {
    border-color: #344658;
    color: #e8ecee;
  }

  .tchart .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .tchart .checkbox .circle {
    box-sizing: border-box;
    position: absolute;
    top: 6px;
    left: 7px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #3cc23f;
    transition: border-width 0.3s linear;
  }

  .dark-theme .tchart .checkbox .circle {
    background-color: #242f3e;
  }

  .tchart .checkbox:hover input ~ .circle {
  }

  .tchart .checkbox input:checked ~ .circle {
    border-width: 10px;
  }

  .tchart .checkbox .symbol {
    position: absolute;
    display: block;
    left: 12px;
    top: 12px;

    width: 10px;
    height: 10px;

    background-image: url('check.svg');
    background-size: cover;
    background-repeat: no-repeat;

    -webkit-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);

    opacity: 0;
    transition: opacity 0.3s linear, transform 0.3s linear;
  }

  .tchart .checkbox input:checked ~ .symbol {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .tchart canvas {
    width: 100%;
    height: 359px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
</style>