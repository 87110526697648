<template>
  <div class="message">
    <div class="message__content">
      <div class="message__title">
        <slot name="title"/>
      </div>
      <div class="message__content-content">
        <slot name="content"/>
      </div>
      <div class="message__buttons">
        <slot name="buttons"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Message',
  }
</script>

<style scoped lang="stylus">
  .message
    flex-grow 1

    display flex
    justify-content center
    align-items center

    height 100%

  .message__content
    max-width 420px
    width 100%
    margin 30px

    display flex
    flex-direction column

    background white

    padding 15px
    text-align center

    .message__title
      font-size 32px
      font-weight 500
      padding-bottom 15px
      color #4d555a

    .message__content-content
      padding 10px 0 30px
      line-height 1.4
      color #6a6a6a
</style>
